import { FRAGMENT as PRODUCT_FRAGMENT } from "@/api/storefront/fragments/product";

export const NAME = 'GET_PRODUCT_BY_HANDLE';

export const QUERY = (variables = {
    identifiers: [],
}, fields = {}) => {
    return `query productByHandle($handle: String!) @inContext {
      product(handle: $handle) {
        ${PRODUCT_FRAGMENT(variables, fields)}
      }
    }`;
};

export default {
    NAME,
    QUERY,
};
