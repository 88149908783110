import { FRAGMENT as PRICE_FRAGMENT } from "./price";

export const NAME = 'CartCostFragment';

const defaultFields = {
    checkoutChargeAmount: true,
    subtotalAmount: true,
    subtotalAmountEstimated: true,
    totalAmount: true,
    totalAmountEstimated: true,
    totalDutyAmount: true,
    totalDutyAmountEstimated: true,
    totalTaxAmount: true,
    totalTaxAmountEstimated: true,
};

export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {})
    };

    return `
      ${!!mergedFields?.checkoutChargeAmount ? `checkoutChargeAmount {
        ${PRICE_FRAGMENT(variables, mergedFields?.checkoutChargeAmount)}
      }` : ''}
      ${!!mergedFields?.subtotalAmount ? `subtotalAmount {
        ${PRICE_FRAGMENT(variables, mergedFields?.subtotalAmount)}
      }` : ''}
      ${!!mergedFields?.subtotalAmountEstimated ? `subtotalAmountEstimated` : ''}
      ${!!mergedFields?.totalAmount ? `totalAmount {
        ${PRICE_FRAGMENT(variables, mergedFields?.totalAmount)}
      }` : ''}
      ${!!mergedFields?.totalAmountEstimated ? `totalAmountEstimated` : ''}
      ${!!mergedFields?.totalDutyAmount ? `totalDutyAmount {
        ${PRICE_FRAGMENT(variables, mergedFields?.totalDutyAmount)}
      }` : ''}
      ${!!mergedFields?.totalDutyAmountEstimated ? `totalDutyAmountEstimated` : ''}
      ${!!mergedFields?.totalTaxAmount ? `totalTaxAmount {
        ${PRICE_FRAGMENT(variables, mergedFields?.totalTaxAmount)}
      }` : ''}
      ${!!mergedFields?.totalTaxAmountEstimated ? `totalTaxAmountEstimated` : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
