import { FRAGMENT as CART_FRAGMENT } from "@/api/storefront/fragments/cart";

export const NAME = 'REMOVE_CART_LINES';

export const QUERY = (variables = {}, fields = {}) => `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) @inContext {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      ${CART_FRAGMENT(variables, fields)}
    }
    userErrors {
      field
      message
    }
  }
}`;

export default {
    NAME,
    QUERY,
};
