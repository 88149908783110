import { NAME as PRICE_FRAGMENT_NAME, FRAGMENT as PRICE_FRAGMENT } from "@/api/storefront/fragments/price";
import { NAME as SELLING_PLAN_FRAGMENT_NAME, FRAGMENT as SELLING_PLAN_FRAGMENT } from "@/api/storefront/fragments/selling-plan";
import { NAME as IMAGE_FRAGMENT_NAME, FRAGMENT as IMAGE_FRAGMENT } from "@/api/storefront/fragments/image";
import { NAME as MEDIA_IMAGE_FRAGMENT_NAME, FRAGMENT as MEDIA_IMAGE_FRAGMENT } from "@/api/storefront/fragments/media-image";
import { NAME as VARIANT_FRAGMENT_NAME, FRAGMENT as VARIANT_FRAGMENT } from "@/api/storefront/fragments/variant";
import { NAME as PRODUCT_FRAGMENT_NAME, FRAGMENT as PRODUCT_FRAGMENT } from "@/api/storefront/fragments/product";
import { NAME as VARIANT_WITH_PRODUCT_FRAGMENT_NAME, FRAGMENT as VARIANT_WITH_PRODUCT_FRAGMENT } from "@/api/storefront/fragments/variant-with-product";
import { NAME as ATTRIBUTE_FRAGMENT_NAME, FRAGMENT as ATTRIBUTE_FRAGMENT } from "@/api/storefront/fragments/attribute";
import { NAME as CART_LINE_COST_FRAGMENT_NAME, FRAGMENT as CART_LINE_COST_FRAGMENT } from "@/api/storefront/fragments/cart-line-cost";
import { NAME as CART_COST_FRAGMENT_NAME, FRAGMENT as CART_COST_FRAGMENT } from "@/api/storefront/fragments/cart-cost";
import { NAME as CART_DISCOUNT_ALLOCATION_FRAGMENTPRICE_FRAGMENT_NAME, FRAGMENT as CART_DISCOUNT_ALLOCATION_FRAGMENT } from "@/api/storefront/fragments/cart-discount-allocation";
import { NAME as CART_LINE_FRAGMENT_NAME, FRAGMENT as CART_LINE_FRAGMENT } from "@/api/storefront/fragments/cart-line";
import { NAME as COMPONENTIZABLE_CART_LINE_FRAGMENT_NAME, FRAGMENT as COMPONENTIZABLE_CART_LINE_FRAGMENT } from "@/api/storefront/fragments/componentizable-cart-line";
import { NAME as BASE_CART_LINE_FRAGMENT_NAME, FRAGMENT as BASE_CART_LINE_FRAGMENT } from "@/api/storefront/fragments/base-cart-line";
import { NAME as CART_FRAGMENT_NAME, FRAGMENT as CART_FRAGMENT } from "@/api/storefront/fragments/cart";
import { NAME as COLOR_METAOBJECT_FRAGMENT_NAME, FRAGMENT as COLOR_METAOBJECT_FRAGMENT } from "@/api/storefront/fragments/metaobjects/color-metaobject";
import { NAME as STYLE_METAOBJECT_FRAGMENT_NAME, FRAGMENT as STYLE_METAOBJECT_FRAGMENT } from "@/api/storefront/fragments/metaobjects/style-metaobject";
import { NAME as NESTED_METAOBJECT_FRAGMENT_NAME, FRAGMENT as NESTED_METAOBJECT_FRAGMENT } from "@/api/storefront/fragments/metaobjects/nested-metaobject";
import { NAME as TAB_METAOBJECT_FRAGMENT_NAME, FRAGMENT as TAB_METAOBJECT_FRAGMENT } from "@/api/storefront/fragments/metaobjects/tab-metaobject";
import { NAME as METAFIELD_FRAGMENT_NAME, FRAGMENT as METAFIELD_FRAGMENT } from "@/api/storefront/fragments/metafield";

export default {
    [PRICE_FRAGMENT_NAME]: PRICE_FRAGMENT,
    [SELLING_PLAN_FRAGMENT_NAME]: SELLING_PLAN_FRAGMENT,
    [IMAGE_FRAGMENT_NAME]: IMAGE_FRAGMENT,
    [MEDIA_IMAGE_FRAGMENT_NAME]: MEDIA_IMAGE_FRAGMENT,
    [VARIANT_FRAGMENT_NAME]: VARIANT_FRAGMENT,
    [PRODUCT_FRAGMENT_NAME]: PRODUCT_FRAGMENT,
    [VARIANT_WITH_PRODUCT_FRAGMENT_NAME]: VARIANT_WITH_PRODUCT_FRAGMENT,
    [ATTRIBUTE_FRAGMENT_NAME]: ATTRIBUTE_FRAGMENT,
    [CART_LINE_COST_FRAGMENT_NAME]: CART_LINE_COST_FRAGMENT,
    [CART_COST_FRAGMENT_NAME]: CART_COST_FRAGMENT,
    [CART_DISCOUNT_ALLOCATION_FRAGMENTPRICE_FRAGMENT_NAME]: CART_DISCOUNT_ALLOCATION_FRAGMENT,
    [CART_LINE_FRAGMENT_NAME]: CART_LINE_FRAGMENT,
    [COMPONENTIZABLE_CART_LINE_FRAGMENT_NAME]: COMPONENTIZABLE_CART_LINE_FRAGMENT,
    [BASE_CART_LINE_FRAGMENT_NAME]: BASE_CART_LINE_FRAGMENT,
    [CART_FRAGMENT_NAME]: CART_FRAGMENT,
    [COLOR_METAOBJECT_FRAGMENT_NAME]: COLOR_METAOBJECT_FRAGMENT,
    [STYLE_METAOBJECT_FRAGMENT_NAME]: STYLE_METAOBJECT_FRAGMENT,
    [NESTED_METAOBJECT_FRAGMENT_NAME]: NESTED_METAOBJECT_FRAGMENT,
    [TAB_METAOBJECT_FRAGMENT_NAME]: TAB_METAOBJECT_FRAGMENT,
    [METAFIELD_FRAGMENT_NAME]: METAFIELD_FRAGMENT,
};
