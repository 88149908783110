import { FRAGMENT as IMAGE_FRAGMENT } from "./image";

export const NAME = 'MediaImageFragment';

const defaultFields = {
    alt: true,
    id: true,
    mediaContentType: true,
    previewImage: true,
    image: true,
};

export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {}),
    };

    return `
      ${!!mergedFields?.alt ? 'alt' : ''}
      ${!!mergedFields?.id ? 'id' : ''}
      ${!!mergedFields?.mediaContentType ? 'mediaContentType' : ''}
      ${!!mergedFields?.previewImage ? `previewImage {
        ${IMAGE_FRAGMENT(variables, mergedFields?.previewImage)}
      }` : ''}
      ${!!mergedFields?.image ? `image {
        ${IMAGE_FRAGMENT(variables, mergedFields?.image)}
      }` : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
