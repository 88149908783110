import { FRAGMENT as CART_LINE_FRAGMENT } from "./cart-line";
import { FRAGMENT as COMPONENTIZABLE_CART_LINE_FRAGMENT } from "./componentizable-cart-line";

export const NAME = 'BaseCartLineFragment';

const defaultFields = {
    componentizableCartLine: false
};
export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {}),
    };

    return `
      ... on CartLine {
        ${CART_LINE_FRAGMENT(variables, mergedFields)}
      }
      ${!!mergedFields?.componentizableCartLine ? `... on ComponentizableCartLine {
        ${COMPONENTIZABLE_CART_LINE_FRAGMENT(variables, mergedFields?.componentizableCartLine)}
      }` : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
