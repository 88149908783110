export const NAME = 'TabMetaobjectFragment';

export const FRAGMENT = () => {
    return `
      fields {
        key
        value
        type
      }
      handle
      id
      type
      updatedAt
    `;
};

export default {
    NAME,
    FRAGMENT,
};
