import { FRAGMENT as IMAGE_FRAGMENT } from "./image";
import { FRAGMENT as PRICE_FRAGMENT } from "./price";
import { FRAGMENT as METAFIELD_FRAGMENT } from "./metafield";

export const NAME = 'VariantFragment';

const defaultFields = {
    availableForSale: true,
    barcode: true,
    compareAtPrice: true,
    id: true,
    image: true,
    price: true,
    quantityAvailable: true,
    requiresShipping: true,
    selectedOptions: true,
    sku: true,
    title: true,
    weight: true,
    weightUnit: true,
    metafields: true,
};

export const FRAGMENT = (variables = {
    variant_identifiers: [],
}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {}),
    };

    return `
      ${!!mergedFields?.availableForSale ? 'availableForSale' : ''}
      ${!!mergedFields?.barcode ? 'barcode' : ''}
      ${!!mergedFields?.compareAtPrice ? `compareAtPrice {
        ${PRICE_FRAGMENT(variables, mergedFields?.compareAtPrice)}
      }` : ''}
      ${!!mergedFields?.id ? 'id' : ''}
      ${!!mergedFields?.image ? `image {
        ${IMAGE_FRAGMENT(variables, mergedFields?.image)}
      }` : ''}
      ${!!mergedFields?.price ? `price {
        amount
        currencyCode
      }` : ''}
      ${!!mergedFields?.quantityAvailable ? 'quantityAvailable' : ''}
      ${!!mergedFields?.requiresShipping ? 'requiresShipping' : ''}
      ${!!mergedFields?.selectedOptions ? `selectedOptions {
        name
        value
      }` : ''}
      ${!!mergedFields?.sku ? 'sku' : ''}
      ${!!mergedFields?.title ? 'title' : ''}
      ${!!mergedFields?.weight ? 'weight' : ''}
      ${!!mergedFields?.weightUnit ? 'weightUnit' : ''}
      ${!!mergedFields?.metafields ? `discount_percent_value: metafield(namespace: "box", key: "discount_value") {
        namespace
        key
        value
      }
      migration_variant_id: metafield(namespace: "migration", key: "variant_id") {
        namespace
        key
        value
      }` : ''}
      ${!!mergedFields?.metafields && variables?.variant_identifiers?.map((identifier) => {
        return `metafields___${identifier?.namespace}___${identifier?.key}: metafield(namespace: "${identifier.namespace}", key: "${identifier.key}") {
          ${METAFIELD_FRAGMENT(variables, mergedFields?.metafields)}
        }`;
      })?.join('\n') || ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
