import { FRAGMENT as TAB_METAOBJECT_FRAGMENT } from "@/api/storefront/fragments/metaobjects/tab-metaobject";

export const NAME = 'MetafieldFragment';

const defaultFields = {
    createdAt: true,
    description: true,
    id: true,
    key: true,
    namespace: true,
    type: true,
    updatedAt: true,
    value: true,
    reference: true,
    references: true,
};
export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {})
    };

    return `
      ${!!mergedFields?.createdAt ? 'createdAt' : ''}
      ${!!mergedFields?.description ? 'description' : ''}
      ${!!mergedFields?.id ? 'id' : ''}
      ${!!mergedFields?.key ? 'key' : ''}
      ${!!mergedFields?.namespace ? 'namespace' : ''}
      ${!!mergedFields?.type ? 'type' : ''}
      ${!!mergedFields?.updatedAt ? 'updatedAt' : ''}
      ${!!mergedFields?.value ? 'value' : ''}
      ${!!mergedFields?.reference ? `reference {
        ... on Metaobject {
            ${TAB_METAOBJECT_FRAGMENT(variables, mergedFields?.reference)}
        }
      }` : ''}
      ${!!mergedFields?.references ? `references(first: 250) {
        edges {
          node {
            ... on Metaobject {
                ${TAB_METAOBJECT_FRAGMENT(variables, mergedFields?.references)}
            }
          }
        }
      }` : ''} 
    `;
};

export default {
    NAME,
    FRAGMENT,
};
