import convertGraphQLImageToShopify from "@/api/storefront/convert/convertGraphQLImageToShopify";

const convertGraphQLBoxMetaobjectToShopify = (node) => ({
    name: node?.fields?.find((field) => field?.key === 'name')?.value,
    short_name: node?.fields?.find((field) => field?.key === 'short_name')?.value,
    handle: node?.handle,
    image: convertGraphQLImageToShopify(node?.fields?.find((field) => field?.key === 'image')?.reference?.image),
    tabs: node?.fields
        ?.find((field) => field?.key === 'box_tab')
        ?.references?.edges?.map((edge) => edge?.node)
        ?.map((node) => {
            let products = [];

            try {
                products = JSON.parse(node?.fields?.find((field) => field?.key?.toLowerCase() === 'tab_products')?.value)?.map((id) => parseInt(id?.replace('gid://shopify/Product/', '')));
            } catch (error) {}

            return {
                name: node?.fields?.find((field) => field?.key?.toLowerCase() === 'name')?.value,
                products
            };
        })
});

export default convertGraphQLBoxMetaobjectToShopify;
