import { FRAGMENT as CART_LINE_FRAGMENT } from "./cart-line";

export const NAME = 'ComponentizableCartLineFragment';

const defaultFields = {
    lineComponents: true,
};

export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {})
    };

    return `
      ${!!mergedFields?.lineComponents ? `lineComponents {
        ${CART_LINE_FRAGMENT(variables, mergedFields?.lineComponents)}
      }` : ''}  
    `;
};

export default {
    NAME,
    FRAGMENT,
};
