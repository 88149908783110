import convertGraphQLMetaobjectToShopify from "@/api/storefront/convert/convertGraphQLMetaobjectToShopify";

const convertGraphQLMetafieldToShopify = (metafield) => {
    const type = metafield?.type;
    if (!type) {
        return null;
    }

    let value = metafield?.value;
    try {
        value = JSON.parse(value);
    } catch (error) {}

    switch (type?.toLowerCase()) {
    case 'list.metaobject_reference': {
        const references = metafield?.references?.edges;
        if (!references) {
            return {
                ...metafield,
                value: []
            };
        }

        if (!references?.length) {
            return {
                ...metafield,
                value: []
            };
        }

        return {
            ...metafield,
            value: references
                ?.map((edge) => edge?.node)
                ?.filter((node) => Boolean(node))
                ?.map((node) => convertGraphQLMetaobjectToShopify(node))
        };
    }

    case 'metaobject_reference': {
        const reference = metafield?.reference;
        if (!reference) {
            return {
                ...metafield,
                value: null
            };
        }

        return {
            ...metafield,
            value: convertGraphQLMetaobjectToShopify(reference)
        };
    }

    default: {
        return {
            ...metafield,
            value
        };
    }
    }
};

export default convertGraphQLMetafieldToShopify;
