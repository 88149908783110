import { FRAGMENT as MEDIA_IMAGE_FRAGMENT } from "@/api/storefront/fragments/media-image";
import { FRAGMENT as NESTED_METAOBJECT_FRAGMENT } from "@/api/storefront/fragments/metaobjects/nested-metaobject";

export const NAME = 'GET_BOX';

const defaultFields = {
    fields: {
        key: true,
        value: true,
        reference: {
            media: true,
            metaobject: true,
        },
        references: {
            media: true,
            metaobject: true,
        },
    },
    handle: true,
    id: true,
    type: true,
    updatedAt: true,
};

export const QUERY = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {})
    };

    return `query metaobject ($handle: MetaobjectHandleInput) {
      metaobject (handle: $handle){
        ${mergedFields?.fields ? `fields {
          ${!!mergedFields?.fields?.key ? 'key' : ''}
          ${!!mergedFields?.fields?.value ? 'value' : ''}
          ${!!mergedFields?.fields?.type ? 'type' : ''}
          ${!!mergedFields?.fields?.reference ? `reference {
            ${!!mergedFields?.fields?.reference?.media ? `...on MediaImage {
              ${MEDIA_IMAGE_FRAGMENT(variables, mergedFields?.fields?.reference?.media)}
            }`: ''}
            ${!!mergedFields?.fields?.reference?.metaobject ? `...on Metaobject {
                ${NESTED_METAOBJECT_FRAGMENT(variables, mergedFields?.fields?.reference?.metaobject)}
            }`: ''}
          }` : ''}
          ${!!mergedFields?.fields?.references ? `references(first: 250) {
            edges {
              node {
                ${!!mergedFields?.fields?.references?.media ? `...on MediaImage {
                  ${MEDIA_IMAGE_FRAGMENT(variables, mergedFields?.fields?.references?.media)}
                }`: ''}
                ${!!mergedFields?.fields?.references?.metaobject ? `...on Metaobject {
                  ${NESTED_METAOBJECT_FRAGMENT(variables, mergedFields?.fields?.references?.metaobject)}
                }`: ''}
              }
            }
          }` : ''}
        }` : ''}
        ${!!mergedFields?.handle ? 'handle' : ''}
        ${!!mergedFields?.id ? 'id' : ''}
        ${!!mergedFields?.type ? 'type' : ''}
        ${!!mergedFields?.updatedAt ? 'updatedAt' : ''}
      }
    }`;
};

export default {
    NAME,
    QUERY,
};
