export const NAME = 'RECOVER_CUSTOMER';

export const QUERY = () => `mutation customerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export default {
    NAME,
    QUERY,
};
