import { FRAGMENT as CART_FRAGMENT } from "@/api/storefront/fragments/cart";

export const NAME = 'UPDATE_ATTRIBUTES';

export const QUERY = (variables = {}, fields = {}) => `mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) @inContext {
  cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
    cart {
      ${CART_FRAGMENT(variables, fields)}
    }
    userErrors {
      field
      message
    }
  }
}`;

export default {
    NAME,
    QUERY,
};
