import { FRAGMENT as PRODUCT_FRAGMENT } from "@/api/storefront/fragments/product";

export const NAME = 'GET_PRODUCT_BY_ID';

export const QUERY = (variables = {}, fields = {}) => `query product($id: ID!) @inContext {
  product(id: $id) {
    ${PRODUCT_FRAGMENT(variables, fields)}
  }
}`;

export default {
    NAME,
    QUERY,
};
