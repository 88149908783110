import convertGraphQLVariantToShopify from "@/api/storefront/convert/convertGraphQLVariantToShopify";
import convertGraphQLImageToShopify from "@/api/storefront/convert/convertGraphQLImageToShopify";
import getIdFromGID from "@/helpers/getIdFromGID";
import convertGraphQLMetafieldToShopify from "@/api/storefront/convert/convertGraphQLMetafieldToShopify";
import { isProductHasOnlyDefaultVariant } from "@/api/helpers/product";

const convertGraphQLProductToShopify = (product) => {
    if (!product) {
        return null;
    }

    const url = new URL(product?.onlineStoreUrl || '', window?.location?.origin);

    const variants = product?.variants?.edges?.map((variant) => {
        return variant?.node;
    })?.map((variant) => {
        const convertedVariant = convertGraphQLVariantToShopify(variant);
        if (!convertedVariant) {
            return null;
        }

        return {
            ...(convertedVariant || {}),
            url: `${url?.pathname || ''}${url?.search || ''}${
                url?.search ? '&' : '?'
            }variant=${convertedVariant?.id}`,
            requires_selling_plan: product?.requiresSellingPlan || false
        };
    }) || [];

    const first_available_variant = variants?.find((variant) => Boolean(variant?.available));

    const first_variant = variants?.length ? variants[0] : null;
    const variant = first_available_variant || first_variant;

    const convertedProduct = {
        available: product?.availableForSale || false,
        first_available_variant,
        compare_at_price: variant?.compare_at_price || null,
        created_at: product?.createdAt || null,
        featured_image: convertGraphQLImageToShopify(product?.featuredImage) || null,
        handle: product?.handle || null,
        options: product?.options?.map((option) => option?.name) || [],
        options_with_values: product?.options?.map((option, index) => ({
            name: option?.name,
            values: option?.values,
            position: index + 1
        })) || [],
        variants,
        url: `${url?.pathname || ''}${url?.search || ''}`,
        id: getIdFromGID(product?.id),
        images: product?.images?.edges?.map((edge) => edge?.node)?.map((node, index) => {
            const convertedImage = convertGraphQLImageToShopify(node);
            if (!convertedImage) {
                return null;
            }

            return {
                ...convertedImage,
                position: index + 1
            };
        }) || [],
        price: variant?.price,
        discount_value: parseFloat(variant?.metafields?.box?.discount_value) || 0,
        show_member_pricing: false, // @TODO add it later,
        member_price: null, // @TODO add it later,
        member_compare_at_price: null, // @TODO add it later,
        requires_selling_plan: product?.requiresSellingPlan || false,
        tags: product?.tags || [],
        title: product?.title || null,
        timer_enable: false, // @TODO add it later,
        metafields: Object.keys(product)
            ?.filter((key) => key?.startsWith('metafields___'))
            ?.map((key) => product[key])
            ?.filter((metafield) => Boolean(metafield?.value))
            ?.map((metafield) => convertGraphQLMetafieldToShopify(metafield))
            ?.reduce((acc, metafield) => {
                const namespace = metafield?.namespace;
                if (!namespace) {
                    return acc;
                }

                const key = metafield?.key;
                if (!key) {
                    return acc;
                }

                if (!acc[namespace]) {
                    acc[namespace] = {};
                }

                acc[namespace][key] = metafield?.value || null;

                return acc;
            }, {}) || {},
        selling_plan_groups: product?.sellingPlanGroups?.edges
            ?.map((edge) => edge?.node)
            ?.map((node) => ({
                app_name: node?.appName,
                name: node?.name,
                options: node?.options,
                selling_plans: node?.sellingPlans?.edges
                    ?.map((edge) => edge?.node)
                    ?.reduce((selling_plans, node) => {
                        const name = node?.name;
                        if (!name) {
                            return selling_plans;
                        }

                        const regex = /(?<order_interval_frequency>\d+)(\s+)?(?<order_interval_unit_type>day|week|month|year)/gm;
                        const rechargeData = regex?.exec(name?.toLowerCase())?.groups;

                        return [
                            ...selling_plans,
                            {
                                id: node?.id,
                                description: node?.description,
                                name: node?.name,
                                selling_plan_id: parseInt(node?.id?.toLowerCase()?.replace('gid://shopify/SellingPlan/'?.toLowerCase(), '')),
                                order_interval_frequency: parseInt(rechargeData?.order_interval_frequency || '0'),
                                order_interval_unit_type: rechargeData?.order_interval_unit_type,
                                price_adjustments: node?.priceAdjustments?.map((price_adjustment) => ({
                                    value: {
                                        percentage: price_adjustment?.adjustmentValue?.adjustmentPercentage || 0.0
                                    }
                                }))
                            }
                        ];
                    }, [])
            }))
    };

    if (!convertedProduct) {
        return null;
    }

    return {
        ...convertedProduct,
        has_only_default_variant: isProductHasOnlyDefaultVariant(convertedProduct)
    };
};

export default convertGraphQLProductToShopify;
