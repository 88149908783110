import { FRAGMENT as PRODUCT_FRAGMENT } from "../product";
import { FRAGMENT as VARIANT_FRAGMENT } from "../variant";

export const NAME = 'NestedMetaobjectFragment';

const defaultFields = {
    fields: {
        key: true,
        value: true,
        type: true,
        reference: {
            product: true,
            variant: true,
        },
    },
    handle: true,
    id: true,
    type: true,
    updatedAt: true,
};

export const FRAGMENT = (variables, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {}),
    };

    return `
      ${!!mergedFields?.fields ? `fields {
        ${!!mergedFields?.fields?.key ? 'key' : ''}
        ${!!mergedFields?.fields?.value ? 'value' : ''}
        ${!!mergedFields?.fields?.type ? 'type' : ''}
        ${!!mergedFields?.fields?.reference ? `reference {
          ${!!mergedFields?.fields?.reference?.product ? `... on Product {
            ${PRODUCT_FRAGMENT(variables, mergedFields?.fields?.reference?.product)}
          }` : ''}  
          ${!!mergedFields?.fields?.reference?.variant ? `... on ProductVariant {
            ${VARIANT_FRAGMENT(variables, mergedFields?.fields?.reference?.variant)}
          }` : ''} 
        }` : ''}
      }` : ''}
      ${!!mergedFields?.handle ? 'handle' : ''}
      ${!!mergedFields?.id ? 'id' : ''}
      ${!!mergedFields?.type ? 'type' : ''}
      ${!!mergedFields?.updatedAt ? 'updatedAt' : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
