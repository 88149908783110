import { FRAGMENT as IMAGE_FRAGMENT } from "./image";
import { FRAGMENT as VARIANT_FRAGMENT } from "./variant";
import { FRAGMENT as SELLING_PLAN_FRAGMENT } from "./selling-plan";
import { FRAGMENT as METAFIELD_FRAGMENT } from "./metafield";

export const NAME = 'ProductFragment';

const defaultFields = {
    id: true,
    availableForSale: true,
    createdAt: true,
    description: true,
    descriptionHtml: true,
    featuredImage: true,
    handle: true,
    isGiftCard: true,
    onlineStoreUrl: true,
    options: true,
    productType: true,
    publishedAt: true,
    requiresSellingPlan: true,
    sellingPlanGroups: true,
    seo: true,
    tags: true,
    title: true,
    totalInventory: true,
    updatedAt: true,
    vendor: true,
    images: true,
    media: true,
    variants: true,
    metafields: true,
};

export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {}),
    };

    return `
      ${!!mergedFields?.id ? 'id' : ''}
      ${!!mergedFields?.availableForSale ? 'availableForSale' : ''}
      ${!!mergedFields?.createdAt ? 'createdAt' : ''}
      ${!!mergedFields?.description ? 'description' : ''}
      ${!!mergedFields?.descriptionHtml ? 'descriptionHtml' : ''}
      ${!!mergedFields?.featuredImage ? `featuredImage {
        ${IMAGE_FRAGMENT(variables, mergedFields?.featuredImage)}
      }` : ''}
      ${!!mergedFields?.handle ? 'handle' : ''}
      ${!!mergedFields?.isGiftCard ? 'isGiftCard' : ''}
      ${!!mergedFields?.onlineStoreUrl ? 'onlineStoreUrl' : ''}
      ${!!mergedFields?.options ? `options(first: 250) {
        id
        name
        values
      }` : ''}
      ${!!mergedFields?.productType ? 'productType' : ''}
      ${!!mergedFields?.publishedAt ? 'publishedAt' : ''}
      ${!!mergedFields?.requiresSellingPlan ? 'requiresSellingPlan' : ''}
      ${!!mergedFields?.sellingPlanGroups ? `sellingPlanGroups (first: 3) {
        edges {
          node {
            appName
            name
            sellingPlans (first: 3) {
              edges {
                node {
                  ${SELLING_PLAN_FRAGMENT(variables, mergedFields?.sellingPlanGroups)}
                }
              }  
            }
          }  
        }
      }` : ''}
      ${!!mergedFields?.seo ? `seo {
        title
        description
      }` : ''}
      ${!!mergedFields?.tags ? 'tags' : ''}
      ${!!mergedFields?.title ? 'title' : ''}
      ${!!mergedFields?.totalInventory ? 'totalInventory' : ''}
      ${!!mergedFields?.updatedAt ? 'updatedAt' : ''}
      ${!!mergedFields?.vendor ? 'vendor' : ''}
      ${!!mergedFields?.images ? `images(first: 250) {
        edges {
          node {
            ${IMAGE_FRAGMENT(variables, mergedFields?.images)}
          }
        }
      }` : ''}
      ${!!mergedFields?.media ? `media (first: 250) {
        edges {
          node {
            __typename
            ... on ExternalVideo {
              alt
              embedUrl
              host
              id
              mediaContentType
              originUrl
              previewImage {
                ${IMAGE_FRAGMENT(variables, mergedFields?.media)}
              }
            }
            ... on MediaImage {
              alt
              id
              image {
                ${IMAGE_FRAGMENT(variables, mergedFields?.media)}
              }
              mediaContentType
              previewImage {
                ${IMAGE_FRAGMENT(variables, mergedFields?.media)}
              }
            }
            ... on Video {
              alt
              id
              mediaContentType
              previewImage {
                ${IMAGE_FRAGMENT(variables, mergedFields?.media)}
              }
              sources {
                format
                height
                mimeType
                url
                width
              }
            }
          }
        }
      }` : ''}
      ${!!mergedFields?.variants ? `variants (first: 100) {
        edges {
          node {
            ${VARIANT_FRAGMENT(variables, mergedFields?.variants)}
          }
        }
      }` : ''}
      ${!!mergedFields?.metafields && variables?.identifiers?.map((identifier) => {
        return `metafields___${identifier?.namespace}___${identifier?.key}: metafield(namespace: "${identifier.namespace}", key: "${identifier.key}") {
          ${METAFIELD_FRAGMENT(variables, mergedFields?.metafields)}
        }`;
      })?.join('\n') || ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
