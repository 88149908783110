import { FRAGMENT as PRODUCT_FRAGMENT } from "@/api/storefront/fragments/product";

export const NAME = 'GET_PRODUCT_BY_IDS';

export const QUERY = (variables = {}, fields = {}) => {
    return `query($ids: [ID!]!) @inContext {
        nodes(ids: $ids) {
            ... on Product {
                ${PRODUCT_FRAGMENT(variables, fields)}
            }
        }
    }`;
};

export default {
    NAME,
    QUERY,
};
