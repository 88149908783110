import { FRAGMENT as PRICE_FRAGMENT } from "./price";

export const NAME = 'CartDiscountAllocationFragment';

const defaultFields = {
    discountedAmount: true,
    title: true,
    code: true,
};
export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {})
    };

    return `
      ${!!mergedFields?.discountedAmount ? `discountedAmount {
        ${PRICE_FRAGMENT(variables, mergedFields?.discountedAmount)}
      }` : ''}
      ${!!mergedFields?.title ? `... on CartAutomaticDiscountAllocation {
        title
      }
      ... on CartCustomDiscountAllocation {
        title
      }` : ''}
      ${!!mergedFields?.code ? `... on CartCodeDiscountAllocation {
        code
      }` : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
