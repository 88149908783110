import getIdFromGID from "@/helpers/getIdFromGID";
import convertGraphQLImageToShopify from "@/api/storefront/convert/convertGraphQLImageToShopify";
import convertGraphQLMetafieldToShopify from "@/api/storefront/convert/convertGraphQLMetafieldToShopify";

const convertGraphQLVariantToShopify = (variant) => {
    if (!variant) {
        return null;
    }

    const convertedVariant = {
        available: variant?.availableForSale || false,
        barcode: variant?.barcode,
        compare_at_price: !!variant?.compareAtPrice?.amount ? variant?.compareAtPrice?.amount * 100 : null,
        featured_image: convertGraphQLImageToShopify(variant?.image),
        image: convertGraphQLImageToShopify(variant?.image),
        id: getIdFromGID(variant?.id),
        requires_shipping: variant?.requiresShipping || false,
        options: variant?.selectedOptions?.map((option) => {
            return option?.name;
        }).filter((name) => {
            return Boolean(name);
        }),
        option1: null,
        option2: null,
        option3: null,
        ...variant?.selectedOptions?.reduce((
            options, option, index
        ) => ({
            ...options,
            [`option${index + 1}`]: option?.value
        }), {}),
        price: variant?.price?.amount * 100,
        title: variant?.title,
        wight: variant?.weight,
        wight_unit: variant?.weightUnit,
        metafields: Object?.keys(variant)?.filter((key) => {
            return key?.startsWith('metafields___');
        })?.map((key) => {
            return variant[key];
        })?.filter((metafield) => {
            return Boolean(metafield?.value);
        })?.map((metafield) => {
            return convertGraphQLMetafieldToShopify(metafield);
        })?.reduce((acc, metafield) => {
            const namespace = metafield?.namespace;
            if (!namespace) {
                return acc;
            }

            const key = metafield?.key;
            if (!key) {
                return acc;
            }

            if (!acc[namespace]) {
                acc[namespace] = {};
            }

            acc[namespace][key] = metafield?.value || null;

            return acc;
        }, {}) || {},
    };

    if (!convertedVariant) {
        return null;
    }

    return {
        ...convertedVariant,
        name: [
            convertedVariant?.option1,
            convertedVariant?.option2,
            convertedVariant?.option3
        ]?.filter((option) => {
            return Boolean(option);
        })?.join(' / ') || "",
    };
};

export default convertGraphQLVariantToShopify;
