const convertGraphQLStyleMetaobjectToShopify = (node) => node?.fields?.reduce((object, field) => {
    const key = field?.key;
    if (!key) {
        return object;
    }

    switch (key?.toLowerCase()) {
        case 'image': {
            return {
                ...object,
                [key]: field?.reference?.image?.url || null
            };
        }
        default: {
            return {
                ...object,
                [key]: field?.value
            };
        }
    }
}, {});

export default convertGraphQLStyleMetaobjectToShopify;
