import { FRAGMENT as PRICE_FRAGMENT } from "./price";
import { FRAGMENT as ATTRIBUTE_FRAGMENT } from "./attribute";
import { FRAGMENT as CART_LINE_COST_FRAGMENT } from "./cart-line-cost";
import { FRAGMENT as CART_DISCOUNT_ALLOCATION_FRAGMENT } from "./cart-discount-allocation";
import { FRAGMENT as VARIANT_WITH_PRODUCT_FRAGMENT } from "./variant-with-product";
import { FRAGMENT as SELLING_PLAN_FRAGMENT } from "./selling-plan";

export const NAME = 'CartLineFragment';

const defaultFields = {
    id: true,
    attributes: true,
    cost: true,
    discountAllocations: true,
    merchandise: true,
    quantity: true,
    sellingPlanAllocation: true,
};

export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {}),
    };

    return `
      ${!!mergedFields?.id ? 'id' : ''}
      ${!!mergedFields?.attributes ? `attributes {
        ${ATTRIBUTE_FRAGMENT(variables, mergedFields?.attributes)}
      }` : ''}
      ${!!mergedFields?.cost ? `cost {
        ${CART_LINE_COST_FRAGMENT(variables, mergedFields?.cost)}
      }` : ''}
      ${!!mergedFields?.discountAllocations ? `discountAllocations {
        ${CART_DISCOUNT_ALLOCATION_FRAGMENT(variables, mergedFields?.discountAllocations)}
      }` : ''}
      ${!!mergedFields?.merchandise ? `merchandise {
        ... on ProductVariant {
            ${VARIANT_WITH_PRODUCT_FRAGMENT(variables, mergedFields?.merchandise)}
        }
      }` : ''}
      ${!!mergedFields?.quantity ? `quantity` : ''}
      ${!!mergedFields?.sellingPlanAllocation ? `sellingPlanAllocation {
        priceAdjustments {
          compareAtPrice {
            ${PRICE_FRAGMENT(variables, mergedFields?.sellingPlanAllocation)}
          }
          perDeliveryPrice {
            ${PRICE_FRAGMENT(variables, mergedFields?.sellingPlanAllocation)}
          }
          price {
            ${PRICE_FRAGMENT(variables, mergedFields?.sellingPlanAllocation)}
          }  
          unitPrice {
            ${PRICE_FRAGMENT(variables, mergedFields?.sellingPlanAllocation)}
          }
        }
        sellingPlan {
          ${SELLING_PLAN_FRAGMENT(variables, mergedFields?.sellingPlanAllocation)}
        }
      }` : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
