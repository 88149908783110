import getIdFromGID from "@/helpers/getIdFromGID";

const convertGraphQLImageToShopify = (image = null) => {
    if (!image) {
        return null;
    }

    return {
        id: getIdFromGID(image?.id),
        width: image?.width || 0,
        height: image?.height || 0,
        src: image?.url || null,
        alt: image?.altText || null,
        aspect_ratio: ![
            !!image?.width,
            !!image?.height
        ]?.includes(false) ? (image.width / image.height)?.toFixed(1) : 1
    };
};

export default convertGraphQLImageToShopify;
