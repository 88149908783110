export const NAME = 'AttributeFragment';

const defaultFields = {
    key: true,
    value: true,
};

// eslint-disable-next-line no-unused-vars
export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {}),
    };

    return `
      ${!!mergedFields?.key ? 'key' : ''}
      ${!!mergedFields?.value ? 'value' : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
