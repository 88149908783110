export const NAME = 'ImageFragment';

const defaultFields = {
    altText: true,
    height: true,
    id: true,
    url: true,
    width: true,
};
// eslint-disable-next-line no-unused-vars
export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {}),
    };

    return `
      ${!!mergedFields?.altText ? "altText" : ""}
      ${!!mergedFields?.height ? "height" : ""}
      ${!!mergedFields?.id ? "id" : ""}
      ${!!mergedFields?.url ? "url" : ""}
      ${!!mergedFields?.width ? "width" : ""}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
