import { FRAGMENT as CART_FRAGMENT } from "@/api/storefront/fragments/cart";

export const NAME = 'UPDATE_BUYER_IDENTITY';

export const QUERY = (variables = {}, fields = {}) => `mutation cartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!) @inContext {
  cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
    cart {
      ${CART_FRAGMENT(variables, fields)}
    }
    userErrors {
      field
      message
    }
  }
}`;

export default {
    NAME,
    QUERY,
};
