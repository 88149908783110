import { FRAGMENT as PRICE_FRAGMENT } from "./price";

export const NAME = 'CartLineCostFragment';

const defaultFields = {
    amountPerQuantity: true,
    compareAtAmountPerQuantity: true,
    subtotalAmount: true,
    totalAmount: true,
};

export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {})
    };

    return `
      ${!!mergedFields?.amountPerQuantity ? `amountPerQuantity {
        ${PRICE_FRAGMENT(variables, mergedFields?.amountPerQuantity)}
      }` : ''}
      ${!!mergedFields?.compareAtAmountPerQuantity ? `compareAtAmountPerQuantity {
        ${PRICE_FRAGMENT(variables, mergedFields?.compareAtAmountPerQuantity)}
      }` : ''}
      ${!!mergedFields?.subtotalAmount ? `subtotalAmount {
        ${PRICE_FRAGMENT(variables, mergedFields?.subtotalAmount)}
      }` : ''}
      ${!!mergedFields?.totalAmount ? `totalAmount {
        ${PRICE_FRAGMENT(variables, mergedFields?.totalAmount)}
      }` : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
