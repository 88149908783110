import { FRAGMENT as CART_FRAGMENT } from "@/api/storefront/fragments/cart";

export const NAME = 'CREATE_CART';

export const QUERY = (variables = {}, fields = {}) => {
    return `mutation cartCreate($input: CartInput) @inContext {
      cartCreate (input: $input) {
        cart {
          ${CART_FRAGMENT(variables, fields)}
        }
        userErrors {
          field
          message
        }
      }
    }`;
};

export default {
    NAME,
    QUERY,
};
