export const NAME = 'PriceFragment';

const defaultFields = {
    amount: true,
    currencyCode: true,
};

// eslint-disable-next-line no-unused-vars
export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {})
    };

    return `
      ${!!mergedFields?.amount ? 'amount' : ''}
      ${!!mergedFields?.currencyCode ? 'currencyCode' : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
