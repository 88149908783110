import { FRAGMENT as CART_FRAGMENT } from "@/api/storefront/fragments/cart";

export const NAME = 'GET_CART';

export const QUERY = (variables = {}, fields = {}) => `query getCart ($id: ID!) @inContext {
  cart(id: $id) {
    ${CART_FRAGMENT(variables, fields)}
  }
}`;

export default {
    NAME,
    QUERY,
};
