import { FRAGMENT as ATTRIBUTE_FRAGMENT } from "./attribute";
import { FRAGMENT as CART_COST_FRAGMENT } from "./cart-cost";
import { FRAGMENT as CART_DISCOUNT_ALLOCATION_FRAGMENT } from "./cart-discount-allocation";
import { FRAGMENT as BASE_CART_LINE_FRAGMENT } from "./base-cart-line";

export const NAME = 'CartFragment';

const defaultFields = {
    id: true,
    attributes: true,
    checkoutUrl: true,
    cost: true,
    discountAllocations: false,
    discountCodes: true,
    updatedAt: true,
    lines: true,
    note: true,
    totalQuantity: true,
    buyerIdentity: true,
};

export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {}),
    };

    return `
      ${!!mergedFields?.id ? 'id' : ''}
      ${!!mergedFields?.attributes ? `attributes {
        ${ATTRIBUTE_FRAGMENT(variables, mergedFields?.attributes)}
      }` : ''}
      ${!!mergedFields?.checkoutUrl ? 'checkoutUrl' : ''}
      ${!!mergedFields?.cost ? `cost {
        ${CART_COST_FRAGMENT(variables, mergedFields?.cost)}
      }` : ''}
      ${!!mergedFields?.discountAllocations ? `discountAllocations {
        ${CART_DISCOUNT_ALLOCATION_FRAGMENT(variables, mergedFields?.discountAllocations)}
      }` : ''}
      ${!!mergedFields?.discountCodes ? `discountCodes {
        applicable
        code
      }` : ''}
      ${!!mergedFields?.updatedAt ? 'updatedAt' : ''}
      ${!!mergedFields?.lines ? `lines(first: 250) {
        edges {
          node {
            ${BASE_CART_LINE_FRAGMENT(variables, mergedFields?.lines)}
          }
        }
      }` : ''}
      ${!!mergedFields?.note ? 'note' : ''}
      ${!!mergedFields?.totalQuantity ? 'totalQuantity' : ''}
      ${!!mergedFields?.buyerIdentity ? `buyerIdentity {
        email
        phone
        customer {
          id
        }
        countryCode
        deliveryAddressPreferences {
          ... on MailingAddress {
            address1
            address2
            city
            provinceCode
            countryCodeV2
            zip
          }
        }
      }` : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
