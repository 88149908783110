export default function (gid) {
    if (!gid) {
        return null;
    }

    const result = /gid:\/\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/(?<id>(\d+))(\?[a-zA-Z0-9]+)?/.exec(gid);
    if (!result) {
        return null;
    }

    const { groups } = result;
    if (!groups) {
        return null;
    }

    const { id } = groups;
    if (!id) {
        return null;
    }

    return +id;
}
