import { FRAGMENT as CART_FRAGMENT } from "@/api/storefront/fragments/cart";

export const NAME = 'UPDATE_CART_LINES';

export const QUERY = (variables = {}, fields = {}) => `mutation cartLinesUpdate ($cartId: ID!, $lines: [CartLineUpdateInput!]!) @inContext {
  cartLinesUpdate(
    cartId: $cartId,
    lines: $lines
  ) {
    cart {
      ${CART_FRAGMENT(variables, fields)}
    }
    userErrors {
      field
      message
    }
  }
}`;

export default {
    NAME,
    QUERY,
};
