import { FRAGMENT as COLOR_METAOBJECT_FRAGMENT } from "@/api/storefront/fragments/metaobjects/color-metaobject";

export const NAME = 'GET_ALL_COLORS';

export const QUERY = () => `query metaobjects {
  metaobjects (type: "color", first: 250){
    edges {
      node {
        ... on Metaobject {
            ${COLOR_METAOBJECT_FRAGMENT()}
        }
      }
    }
  }
}`;

export default {
    NAME,
    QUERY,
};
