import { FRAGMENT as VARIANT_FRAGMENT } from "./variant";
import { FRAGMENT as PRODUCT_FRAGMENT } from "./product";

export const NAME = 'VariantWithProductFragment';

const defaultFields = {
    product: true,
};

export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {})
    };

    return `
      ${VARIANT_FRAGMENT(variables, mergedFields)}
      ${!!mergedFields?.product ? `product {
        ${PRODUCT_FRAGMENT(variables, mergedFields?.product)}
      }` : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
