export const NAME = 'SellingPlanFragment';

const defaultFields = {
    id: true,
    description: true,
    name: true,
    options: true,
    priceAdjustments: true,
};

// eslint-disable-next-line no-unused-vars
export const FRAGMENT = (variables = {}, fields = defaultFields) => {
    const mergedFields = {
        ...defaultFields,
        ...(fields || {})
    };

    return `
      ${!!mergedFields?.id ? 'id' : ''}
      ${!!mergedFields?.description ? 'description' : ''}
      ${!!mergedFields?.name ? 'name' : ''}
      ${!!mergedFields?.options ? `options {
        name
        value
      }` : ''}
      ${!!mergedFields?.priceAdjustments ? `priceAdjustments {
        adjustmentValue {
          __typename
          ... on SellingPlanPercentagePriceAdjustment {
            adjustmentPercentage
          }
        }
      }` : ''}
    `;
};

export default {
    NAME,
    FRAGMENT,
};
