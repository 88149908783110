import { FRAGMENT as STYLE_METAOBJECT_FRAGMENT } from "@/api/storefront/fragments/metaobjects/style-metaobject";

export const NAME = 'GET_ALL_STYLES';

export const QUERY = () => `query metaobjects {
  metaobjects (type: "style", first: 250){
    edges {
      node {
        ... on Metaobject {
            ${STYLE_METAOBJECT_FRAGMENT()}
        }
      }
    }
  }
}`;

export default {
    NAME,
    QUERY,
};
