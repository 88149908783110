import { FRAGMENT as MEDIA_IMAGE_FRAGMENT } from "../media-image";

export const NAME = 'ColorMetaobjectFragment';

export const FRAGMENT = () => {
    return `
      fields {
        key
        value
        type
        reference {
          ... on MediaImage {
            ${MEDIA_IMAGE_FRAGMENT()}
          }
        }
      }
      handle
      id
      type
      updatedAt
    `;
};

export default {
    NAME,
    FRAGMENT,
};
